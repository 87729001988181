@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-orange {
    @apply py-3 px-4 bg-orange-brown text-white font-semibold rounded-lg shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-0 font-inter-regular;
  }
  .btn-outline {
    @apply py-2 px-4 bg-white text-light-purple rounded-lg shadow-md hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-0 font-inter-regular border;
  }
  .btn-purple {
    @apply py-2 px-4 bg-light-purple text-white rounded-lg focus:ring-transparent shadow-md hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-regular border;
  }
  .btn-mini {
    @apply py-1 bg-light-purple text-xxs text-white rounded-lg focus:ring-transparent shadow-md hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-regular border;
  }
  .d-row {
    @apply flex flex-row items-center;
  }
  .d-col {
    @apply flex flex-col;
  }
  .t-head {
    @apply py-2 md:text-left tracking-wider text-center font-bold;
  }
  .t-row {
    @apply text-xxs py-1 md:py-3 md:text-xxs lg:text-xs text-black font-inter-medium font-normal;
  }
  .input-light {
    @apply text-xs my-1 font-inter-regular rounded-md border border-gray-300 w-full py-2 px-2 text-gray-700 focus:outline-none focus:ring-1;
  }
  .input-label {
    @apply text-black text-xs font-inter-regular;
  }
  .btn-outline-pink {
    @apply py-2 px-5 bg-transparent text-accent-pink rounded focus:ring-transparent hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium border-accent-pink border text-xs;
  }
  .btn-pink {
    @apply py-2 px-5 bg-accent-pink text-white rounded focus:ring-transparent hover:bg-dark-accent-pink hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium text-xs;
  }
  .modal-visible {
    @apply fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-10;
  }
}
